<template>
  <div class="search-download">
    <div v-if="tabsList.length > 0" class="download-content container">
      <ul class="download-list">
        <li v-for="(item, index) in tabsList" :key="index" class="download-item" @click="downHandler(item)">
          <div class="left">
            <i class="iconfont icon-pdf"></i>
            <span class="file-name" v-html="item.title">
              {{ item.title }}
            </span>
          </div>
          <div class="right">
            <i class="iconfont icon-a-xiazai1x"></i>
          </div>
        </li>
      </ul>
    </div>
    <search-no-data v-else />
    <!-- 未登录提示 -->
    <download-dialog :download-visible="downloadVisible" @close="handleClose" />
  </div>
</template>

<script>
import SearchNoData from '@/components/search/SearchNoData.vue'
import DownloadDialog from '@/components/support/DownloadDialog.vue'

export default {
  components: {
    SearchNoData,
    DownloadDialog
  },
  props: {
    tabsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      downloadVisible: false // 未登录提示弹窗
    }
  },
  methods: {
    // 下载
    downHandler(item) {
      if (item.power === '1') {
        this.downloadVisible = true
      } else {
        window.open(item.download)
      }
    },
    // 关闭弹窗
    handleClose() {
      this.downloadVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.search-download {
  background-color: #f4f4f5;
  .download-content {
    padding: 24px 0;
    background-color: #F4F4F5;
    .download-list {
      .download-item {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 10px;
        padding: 18px 30px 18px 21px;
        background-color: #fff;
        border-radius: 10px;
        .transition();
        &:hover {
          .shadow();
        }
        .left {
          .file-name {
            display: inline-block;
            margin-left: 13px;
            font-size: 14px;
            color: #303133;
            line-height: 19px;
          }
        }
        .left,
        .right {
          .iconfont {
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
