<template>
  <div class="search-solutions">
    <div v-if="tabsList.length > 0" class="tabs-box container">
      <ul class="tabs-list">
        <li v-for="(item, index) in tabsList" :key="index" class="tabs-item">
          <a :href="`/${$route.params.site}/${$route.params.lang}/solutions/detail?id=${item.id}`">
            <div class="img-box">
              <img :src="item.image" alt="" />
            </div>
            <div class="content-box" v-html="item.title">
              {{ item.title }}
            </div>
          </a>
        </li>
      </ul>
    </div>
    <search-no-data v-else />
  </div>
</template>

<script>
import SearchNoData from '@/components/search/SearchNoData.vue'

export default {
  components: {
    SearchNoData
  },
  props: {
    tabsList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="less" scoped>
.search-solutions {
  background-color: #f4f4f5;
  .tabs-box {
   padding: 24px 0 72px;
   .tabs-list {
     display: flex;
     flex-wrap: wrap;
     .tabs-item {
       width: 32.5%;
       margin-right: 16px;
       margin-top: 16px;
       background-color: #fff;
       .img-box {
         height: 79.4;
         img {
           border-radius: 7px;
           width: 100%;
           height: 100%;
         }
       }
       .content-box {
         padding: 14px 14px 24px;
         font-size: 18px;
         font-weight: 600;
         color: #232931;
         line-height: 18px;
       }
       &:nth-child(3n) {
         margin-right: 0;
       }
       &:nth-child(-n + 3) {
         margin-top: 0;
       }
     }
   }
 }
  @media screen and (max-width: 1320px) {
    .tabs-box {
      .tabs-list {
        .tabs-item {
          width: 31.5%;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .tabs-box {
      padding: 16px 0 40px 0;
      .tabs-list {
        .tabs-item {
          width: 100%;
          margin-right: 0;
          & + .tabs-item {
            margin-top: 16px;
          }
        }
      }
    }
  }
}
</style>
