<template>
  <div class="search-no-data">
    <img src="@/assets/img/search/search-no-data.png" alt="" />
    <div class="no-tips">
      {{ LP.lang_search_no_data }}
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.search-no-data {
  padding: 100px 0;
  text-align: center;
  background-color: #f4f4f5;
  .no-tips {
    font-size: 24px;
    font-weight: 600;
    color: #979797;
    line-height: 32px;
  }
}
</style>
