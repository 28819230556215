import { fetch } from '@/plugins/axios.js'
import lang from '@/utils/lang'
import site from '@/utils/site'

const apiUrl = {
  searchHeat: `/search/heat`, // 搜索热词
  search: `/search/index`, // 搜索
  searchMatch: `/search/matching` // 搜索联想
}

// 搜索热词
export const getSearchHeat = () => fetch(`${apiUrl.searchHeat}/${lang}/${site}`)
// 搜索
export const getSearch = (params) => fetch(`${apiUrl.search}/${lang}/${site}`, params, 'post')
// 搜索联想
export const getSearchMatch = (params) => fetch(`${apiUrl.searchMatch}/${lang}/${site}` + `?keyword=${params.keyword}`)

