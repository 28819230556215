<template>
  <div class="search-wrap">
    <div class="search-box container">
      <el-autocomplete
        v-model.trim="keyword"
        class="inline-input"
        :fetch-suggestions="querySearch"
        :placeholder="LP.lang_search"
        :trigger-on-focus="false"
        @keyup.enter.native="handleSearch(keyword)"
      >
        <i
          slot="prefix"
          class="icon el-icon-search"
          @click="handleSearch(keyword)"
        >
        </i>
        <i
          v-if="keyword"
          slot="suffix"
          class="iconfont icon-shanchu_1 icon"
          @click="clearHandler"
        >
        </i>
      </el-autocomplete>
      <div class="result-title">
        {{ LP.lang_results }}
      </div>
    </div>
    <div
      v-loading="loading"
      :element-loading-text="LP.lang_loading"
      element-loading-spinner="el-icon-loading"
    >
      <div class="search-tabs">
        <ul class="search-tabs-list container">
          <li
            v-for="(item, index) in tabs"
            :key="index"
            class="search-tabs-item"
            :class="{active: tabIndex === index}"
            @click="handleTabs(item, index)"
          >
            <span class="name">{{ item.name }}({{ item.total }})</span>
          </li>
        </ul>
      </div>
      <search-products v-if="tabIndex === 0" :tabs-list="productsList" />
      <search-solutions v-if="tabIndex === 1" :tabs-list="solutionsList" />
      <search-download v-if="tabIndex === 2" :tabs-list="downList" />
      <search-success v-if="tabIndex === 3" :tabs-list="successList" />
      <div v-if="total > 10" class="public-pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SearchProducts from '@/components/search/SearchProducts.vue'
import SearchSolutions from '@/components/search/SearchSolutions.vue'
import SearchDownload from '@/components/search/SearchDownload.vue'
import SearchSuccess from '@/components/search/SearchSuccess.vue'
import { getSearch } from '@/api/search'

export default {
  components: {
    SearchProducts,
    SearchSolutions,
    SearchDownload,
    SearchSuccess
  },
  data() {
    return {
      restaurants: [], // 搜索联想
      keyword: '', // 关键词
      tabs: [], // tabs列表
      tabIndex: 0, // tabs下标
      total: 0, // 列表总数
      type: '', // 搜索类型
      productsList: [], // 产品列表
      solutionsList: [], // 解决方案列表
      downList: [], // 下载列表
      successList: [], // 成功案例列表
      loading: false,
      initFnc: 0
    }
  },
  computed: {
    ...mapState({
      whiteHeader: (state) => state.header.whiteHeader
    })
  },
  watch: {
    '$route'(val) {
      if (val.query.keyword !== '') {
        this.keyword = val.query.keyword
        this.getSearchHandler(val.query.keyword, this.type)
      } else {
        this.$message.error(this.LP.lang_enter_keywords)
      }
    }
  },
  mounted() {
    this.$store.commit('header/SET_WHITE_HEADER', true)
    this.keyword = this.$route.query.keyword
    if (this.keyword) {
      this.getSearchHandler(this.keyword)
    }
    this.restaurants = this.loadAll()
  },
  methods: {
    // 获取搜索结果列表
    getSearchHandler(keyword, type = 'products', page = 1, limit = 10) {
      this.loading = true
      getSearch({
        keyword,
        type,
        page,
        limit
      }).then(res => {
        if (res.data) {
          switch (type) {
            case 'products':
              this.productsList = res.data.list
              break
            case 'solutions':
              this.solutionsList = res.data.list
              break
            case 'download':
              this.downList = res.data.list
              break
            case 'success':
              this.successList = res.data.list
              break
          }
          this.total = res.data.totalCount
          this.tabs = res.data.count.map(item => ({
            ...item,
            title: item.name,
            total: item.count
          }))
          this.$store.commit('common/SET_MATE', res.data.seo)
        }
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    // 搜索联想
    querySearch(queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    loadAll() {
      return []
    },
    // 输入框搜索
    handleSearch(keyword) {
      if (keyword) {
        this.getSearchHandler(keyword)
        this.$router.push({
          path: `/${this.$route.params.site}/${this.$route.params.lang}/search`,
          query: {
            keyword: keyword
          }
        })
      } else {
        this.$message.error(this.LP.lang_enter_keywords)
      }
    },
    // 清除输入框
    clearHandler() {
      this.keyword = ''
    },
    // tabs切换
    handleTabs(item, index) {
      this.tabIndex = index
      this.type = item.title
      this.getSearchHandler(this.keyword, item.title)
    },
    // 分页切换
    handleCurrentChange(val) {
      this.getSearchHandler(this.keyword, this.type, val)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
  .search-wrap {
    margin-top: 79px;
    border-top: 1px solid #ddd;
    .search-box {
      text-align: center;
      padding: 49px 0 24px;
      /deep/ .el-autocomplete {
        width: 100%;
        max-width: 632px;
        .el-input {
          .el-input__inner {
            text-align: center;
            border-radius: 28px;
            padding: 0 52px 0 72px;
            height: 54px;
            border: 1px solid #909399;
            font-size: 18px;
            color: #303133;
            line-height: 24px;
            &::placeholder {
              color: #979797;
            }
          }
          .el-input__prefix {
            cursor: pointer;
            left: 28px;
            .icon {
              font-size: 32px;
              color: #242A32;
              line-height: 54px;
            }
          }
          .el-input__suffix {
            cursor: pointer;
            right: 28px;
             .icon {
              font-size: 24px;
              color: #909399;
              line-height: 54px;
            }
          }
        }
      }
      .inline-input {
        margin-bottom: 48px;
      }
      .result-title {
        font-size: 18px;
        font-weight: 600;
        color: #303133;
        line-height: 24px;
      }
    }
    .public-pagination {
      background-color: #f4f4f5;
    }
    .search-tabs {
      border-top: 1px solid #DDDDDD;
      border-bottom: 2px solid #DDDDDD;
      .search-tabs-list {
        display: flex;
        justify-content: center;
        .search-tabs-item {
          padding: 19px 0 21px;
          border-bottom: 2px solid transparent;
          cursor: pointer;
          & + .search-tabs-item {
            margin-left: 96px;
          }
          .name {
            font-size: 18px;
            font-weight: 600;
            color: #979797;
            line-height: 24px;
            display: block;
            .transition();
          }
          &:hover {
            .name {
              color: #232931;
            }
          }
          &.active {
            border-bottom: 2px solid #232931;
            .name {
              color: #232931;
            }
          }
        }
      }
    }
    @media screen and (max-width: 1024px) {
      .search-tabs {
        .search-tabs-list {
          .search-tabs-item {
            & + .search-tabs-item {
              margin-left: 40px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      .search-box {
        padding: 40px 0 16px;
        /deep/ .el-autocomplete {
          .el-input {
            .el-input__inner {
              height: 48px;
              font-size: 16px;
            }
            .el-input__prefix {
              .icon {
                font-size: 26px;
                line-height: 48px;
              }
            }
            .el-input__suffix {
              .icon {
                font-size: 22px;
                line-height: 48px;
              }
            }
          }
        }
        .inline-input {
          margin-bottom: 24px;
        }
      }
      .search-tabs {
        .search-tabs-list {
          overflow-x: auto;
          justify-content: left;
          .search-tabs-item {
            padding: 14px 0;
            .name {
              font-size: 14px;
              line-height: 20px;
            }
            & + .search-tabs-item {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
</style>
