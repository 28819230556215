<template>
  <div class="search-products">
    <div v-if="tabsList.length > 0" class="tabs-box container">
      <ul class="tabs-list">
        <li v-for="(item, index) in tabsList" :key="index" class="tabs-item">
          <a :href="`/${$route.params.site}/${$route.params.lang}/products/productDetail?product_id=${item.product_id}`">
            <div class="img-box">
              <img :src="item.product_thumb" alt="" />
            </div>
            <div class="content-box">
              <div class="title-box">
                <span class="content-title" v-html="item.product_name">{{ item.product_name }}</span>
                <i class="iconfont icon-a-youjiantou1x"></i>
              </div>
              <div class="content-des" v-html="item.product_description">
                {{ item.product_description }}
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <search-no-data v-else />
  </div>
</template>

<script>
import SearchNoData from '@/components/search/SearchNoData.vue'

export default {
  components: {
    SearchNoData
  },
  props: {
    tabsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
.search-products {
  background-color: #f4f4f5;
  .tabs-box {
    padding: 24px 0 72px;
    .tabs-list {
      display: flex;
      flex-wrap: wrap;
      .tabs-item {
        width: 24%;
        margin-right: 16px;
        margin-top: 16px;
        background-color: #fff;
        .img-box {
          padding: 0 27px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .content-box {
          padding: 8px 16px;
          border-top: 1px solid #E4E7ED;
          .title-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .content-title {
              font-size: 18px;
              color: #333333;
              line-height: 24px;
            }
            .iconfont {
              font-size: 18px;
            }
          }
          .content-des {
            margin-top: 8px;
            font-size: 14px;
            color: #666666;
            line-height: 19px;
          }
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:nth-child(-n + 4) {
          margin-top: 0;
        }
      }
    }
  }
  @media screen and (max-width: 1320px) {
    .tabs-box {
      .tabs-list {
        .tabs-item {
          width: 23.5%;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .tabs-box {
      .tabs-list {
        .tabs-item {
          width: 31.5%;
          &:nth-child(4n) {
            margin-right: 16px;
          }
          &:nth-child(-n + 4) {
            margin-top: 16px;
          }
          &:nth-child(3n) {
            margin-right: 0px;
          }
          &:nth-child(-n + 3) {
            margin-top: 0px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .tabs-box {
      padding: 16px 0 40px;
      .tabs-list {
        .tabs-item {
          width: 100%;
          margin-right: 0;
          & + .tabs-item {
            margin-top: 16px;
          }
          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
