<template>
  <div class="search-success">
    <div v-if="tabsList.length > 0" class="success-content container">
      <ul class="slogan-list">
        <li
          v-for="(item, index) in tabsList"
          :key="index"
          :style="`background: url(${item.image}) center center / cover no-repeat`"
          class="items"
        >
          <div class="text-bg clearfix">
            <div class="text-box">
              <h5 class="title" v-html="item.title">{{ item.title }}</h5>
              <p class="desc" v-html="item.description">{{ item.description }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <search-no-data v-else />
  </div>
</template>

<script>
import SearchNoData from '@/components/search/SearchNoData.vue'

export default {
  components: {
    SearchNoData
  },
  props: {
    tabsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common';
.search-success {
  background-color: #f4f4f5;
  .success-content {
    padding: 24px 0;
     .slogan-list {
      display: flex;
      flex-wrap: wrap;
      .items {
        width: 32.5%;
        height: 320px;
        background: #D8D8D8;
        border-radius: 5px;
        margin-right: 16px;
        margin-top: 16px;
        display: flex;
        position: relative;
        cursor: pointer;
        &:nth-child(3n) {
         margin-right: 0;
       }
        &:nth-child(-n + 3) {
          margin-top: 0;
        }
        .text-bg {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 66px;
          background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.77) 100%);
          overflow: hidden;
          padding: 24px;
          border-radius: 5px;
          .transition(0.4s);
          .text-box {
            .title {
              font-size: 18px;
              font-weight: 600;
              color: #FFFFFF;
              line-height: 18px;
              padding-bottom: 24px;
            }
            .desc {
              font-size: 14px;
              color: #F4F4F5;
              line-height: 19px;
            }
          }
        }
        &:hover {
          .text-bg {
            height: 100%;
            .text-box {
              position: absolute;
              bottom: 24px;
              .title {
                padding-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1320px) {
    .success-content {
      .slogan-list {
        .items {
          width: 31.5%;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .success-content {
      .slogan-list {
        .items {
          height: 240px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .success-content {
      padding: 16px 0;
      .slogan-list {
        .items {
          width: 100%;
          & + .items {
            margin-top: 16px;
          }
        }
      }
    }
  }
}
</style>
